/* Ajusta el contenedor de los Toasts para que se muestre sobre el formulario */
.toast-container {
    position: fixed;
    top: 10px; /* Ajusta la distancia desde el top según sea necesario */
    right: 10px; /* Ajusta la distancia desde el right según sea necesario */
    z-index: 1050; /* Asegúrate de que esté por encima de otros elementos */
}

/* Estilos personalizados para el Toast */
.toast {
    color: #ffffff; /* Color de letra blanco */
    background-color: #000000; /* Fondo negro como ejemplo, ajusta si es necesario */
}

.toast-success {
    background-color: #28a745; /* Color de fondo verde para el éxito */
}

.toast-danger {
    background-color: #dc3545; /* Color de fondo rojo para el error */
}

/* Asegúrate de que el texto dentro del Toast sea blanco */
.toast-body {
    color: #ffffff;
}


  