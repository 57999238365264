.notificaciones-activas {
    color: red; /* Cambia el color del icono */
    animation: pulse 1s infinite; /* Agrega una animación de pulso */
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }